<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    query="&sort=-id"
    entity-name="库存预警"
    hideDelete
    add-btn=""
    hide-show-detail
    show-row-num
    :rules="rules"
    :columns="columns"
    :search-key-type="searchKeyType"
    :action="action"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item>
        <a-input v-model="form.name" placeholder="输入商品名称/SKU" allowClear></a-input>
      </a-form-model-item>
    </template>
    <template v-slot:edit="{ form }">
      <a-form-model-item label="商品名称" prop="name">
        {{ form.name }}
      </a-form-model-item>

      <a-form-model-item label="当前库存" prop="goods_num">
        {{ form.goods_num }}
      </a-form-model-item>

      <a-form-model-item label="预警数量" prop="warn_num">
        <a-input-number
          v-model="form.warn_num"
          placeholder="请输入数字"
          :min="0"
          :step="1"
          allowClear
          style="width: 100%;"
        ></a-input-number>
      </a-form-model-item>

      <a-form-model-item label="单次最大领用数量" prop="maximum">
        <a-input-number
          v-model="form.maximum"
          placeholder="请输入数字"
          :min="0"
          :step="1"
          allowClear
          style="width: 100%;"
        ></a-input-number>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import { isEmpty } from "../../common/js/tool";

export default {
  name: "WarningList",
  data() {
    return {
      url: "/admin/asset-goods",
      searchKeyType: {
        name: 2,
      },
      columns: [
        { title: "SKU", dataIndex: "sku" },
        { title: "商品名称", dataIndex: "name" },
        { title: "库存数量", dataIndex: "goods_num" },
        { title: "预警数量", dataIndex: "warn_num" },
        { title: "单次最大领用数量", dataIndex: "maximum", customRender: (text) => (isEmpty(text) ? "-" : text) },
      ],
      rules: {
        warn_num: [{ required: true, message: "请输入预警数量", trigger: "blur" }],
        maximum: [{ required: true, message: "请输入单次最大领用数量", trigger: "blur" }],
      },
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped></style>
